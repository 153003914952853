export const columns = [
  {
    title: '序号',
    key: 'index',
    width: 64,
    scopedSlots: {
      customRender: 'index'
    }
  },
  {
    title: '加油站名称',
    key: 'siteName',
    align: 'left',
    minWidth: 150
  },
  {
    title: '液位仪编号',
    key: 'meterNum',
    minWidth: 100
  },
  {
    title: '油品',
    key: 'oilType'
  },
  {
    title: '液位仪采集出油量',
    key: 'oilCapacity',
    minWidth: 150
  },
  {
    title: '加油机销量',
    key: 'oilOut',
    minWidth: 130
  },
  {
    title: '异常内容',
    key: 'msg'
  },
  {
    title: '报警时间',
    key: 'createTime'
  }
];
